<template>
  <section>
    <div id="new-roles" class="flex">
      <Card id="permission-name">
        <b-field grouped>
          <Field ref="forms" :label="$t('role.roles.name')" class="w-100" v-model="name" required />
        </b-field>
      </Card>
      <Card id="permissions">
        <template #header>
          <div class="flex f-jsb f-ai">
            <p>
              {{ $t('role.roles.newRole') }}
            </p>
            <Input-search class="mb-1" :placeholder="$t('global.searchModule')" v-model="search" />
          </div>
        </template>
        <div>
          <div class="flex f-wrap is-justify-content-space-between card-permission">
            <Permission
              class="permission"
              :data="item"
              v-for="(item, index) in filterData"
              :key="index"
              :isNew="true"
              @setDataNewRol="setDataNewRol"
              :routesActive="currentData"
            />
          </div>
        </div>
        <template #footer>
          <div class="flex f-full f-ai f-jce">
            <div class="buttons">
              <Link to="/advanced-settings" isRouter>
                <b-button type="is-danger" outlined :label="$t('button.cancel')" class="mr-5" />
              </Link>
              <Button
                type="is-primary"
                :label="$t('button.save')"
                @click="onSave"
                :permission="Permission.create"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </section>
</template>

<script>
import { Card, InputSearch, Button, Link } from '@/components';
import Permission from '@/components/AdvancedSettings/RolesPermissions/Permission.vue';
import Field from '@/components/Field.vue';
import { toast } from '@/utils/dialog';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Card,
    Field,
    InputSearch,
    Permission,
    Button,
    Link
  },
  data() {
    return {
      currentData: [],
      currentUserType: null,
      data: [],
      isNewType: true,
      name: null,
      previousName: null,
      previousRoutes: [],
      search: ''
    };
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', ['menu.rol', 'role.roles.newRole']);
    this.getRoutes();
  },
  computed: {
    filterData() {
      const data = this.data;
      const search = this.search;
      let value = {};
      const compareValue = (str, query) => {
        return String(str).toLowerCase().indexOf(query) > -1;
      };
      Object.keys(data).map(function (key, index) {
        if (compareValue(data[key][0].group_route, search)) {
          value[index] = data[key];
        }
      });

      return value;
    },
    Permission() {
      return Permissions.Rol_Permissions;
    }
  },
  methods: {
    //BUTTON SAVE
    async onSave() {
      if (this.validate()) {
        if (this.isNewType) {
          const { data } = await this.Api.post('/user_types', {
            name: this.name,
            description: `new type ${this.name}`
          });
          this.previousName = this.name;
          this.currentUserType = data.id;
        } else if (!this.isNewType && this.previousName !== this.name) {
          await this.Api.put(`/user_types/${this.currentUserType}`, {
            name: this.name
          });
        }
        if (this.currentUserType) {
          if (this.isNewType) {
            this.currentData.map(async (element) => {
              await this.Api.post(`/user_types/${this.currentUserType}/routes/${element}`);
            });
          } else {
            this.previousRoutes.map(async (element) => {
              if (!this.currentData.find((e) => e === element)) {
                await this.Api.delete(`/user_types/${this.currentUserType}/routes/${element}`);
              }
            });
            !this.currentData.map(async (element) => {
              if (!this.previousRoutes.find((e) => e === element)) {
                await this.Api.post(`/user_types/${this.currentUserType}/routes/${element}`);
              }
            });
          }
          this.previousRoutes = this.currentData;
          this.isNewType
            ? toast('success', this.$t('routes.savedNewUserType'), 5000)
            : toast('success', this.$t('routes.updateNewUserType'), 5000);
        }
        this.isNewType = false;
      }
    },
    //VALIDATE INFORMATION
    validate() {
      if (this.name !== null) {
        if (this.currentData.length >= 1) {
          return true;
        } else {
          toast('warning', this.$t('error.selectOnePermission'), 5000);
        }
      } else {
        toast('warning', this.$t('error.nameIsRequired'), 5000);
      }
    },
    //GET ROUTES API
    async getRoutes() {
      const myRoutes = {};

      const { data } = await this.Api.get('/user_types/routes');

      data.map((element) => {
        const groupName = element.group_route.toLowerCase().replace(/ /g, `_`);
        if (!myRoutes[groupName]) myRoutes[groupName] = [];
        myRoutes[groupName] = [...myRoutes[groupName], element];
      });

      this.data = myRoutes;
    },
    //GET CURRENT DATA, APPROPRIATE IDs ARE ADDED AND REMOVED FOR FUTURE COMPARISON.
    setDataNewRol(data, addElement) {
      if (addElement) {
        this.currentData = [...this.currentData, data];
      } else {
        this.currentData = this.currentData.filter((element) => element !== data);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
#new-roles
  height: calc(100% - 100px)
  #permission-name
    min-width: 300px
  #permissions
    width: 100%
    min-height: 100%
.card-permission
  width: 100%
  height: calc(100vh - 300px) !important
  overflow-y: auto
.permission
  height: 335px
  width: 100%
  @media only screen and (min-width: $bp-xl)
    width: 48%
    @media only screen and (min-width: $bp-xxl)
      width: 30%
</style>
